
import RedacaoCorrigida from "@/interfaces/RedacaoCorrigida";
import Redacao from "@/interfaces/Redacao";
import BaseService from "@/services/admin/BaseService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import RedacaoCorrigidaColumn from "@/util/table-columns/RedacaoCorrigidaColumn";
import Column from "@/interfaces/Column";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const redacao = ref<Redacao>();
    const filter1 = ref<string>();
    const filter2 = ref<string>();
    const urlImg = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const redacoesSubmetidas = ref<RedacaoCorrigida[]>();
    const redacoesCorrigidas = ref<RedacaoCorrigida[]>();
    const columns = ref<Column[]>(RedacaoCorrigidaColumn);
    const redacaoSelecionada = ref<RedacaoCorrigida>();
    const modal = ref(false);
    const form = ref<any>({
      redacao_corrigida_id: "",
      comentario: "",
      notas: [],
    });

    const openModal = (row: RedacaoCorrigida): void => {
      redacaoSelecionada.value = row;
      form.value.redacao_corrigida_id = row.id;
      form.value.notas = [];
      form.value.comentario = row.comentario;
      if (redacaoSelecionada.value.parametros_corrigidos.length > 0) {
        redacaoSelecionada.value.parametros_corrigidos.forEach((p) => {
          form.value.notas.push({ parametro_id: p.parametro.parametro.id, nota: p.nota });
        });
      } else {
        redacaoSelecionada.value.redacao.parametros.forEach((p) => {
          form.value.notas.push({ parametro_id: p.parametro.id, nota: 0 });
        });
      }
      modal.value = true;
    };

    const submit = (): void => {
      BaseService.store("redacoes/corrigir", form.value)
        .then(() => {
          showNotify({
            type: "positive",
            message: `Redação corrigida com sucesso`,
          });
          getRedacoes();
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: "Erro ao corrigir redação",
          });
        });
    };

    const refazer = (): void => {
      console.log(form.value);
      $q.dialog({
        title: 'Mensagem',
        message: 'Qual mensagem deseja enviar?',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        persistent: true
      }).onOk((data: any) => {
        BaseService.store(`redacoes-corrigidas/refazer/${form.value.redacao_corrigida_id}`, { mensagem: data})
          .then((res) => {
            showNotify({
              type: "positive",
              message: `Solicitação de refazer redação feita com sucesso`,
            });
            getRedacoes();
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao refazer redação",
            });
          });
      });
    }

    const getRedacoes = (): void => {
      BaseService.list(`redacoes-corrigidas/find-by-redacao/${route.params.id_redacao}/${route.params.id_turma}`)
        .then(({ data }) => {
          redacoesSubmetidas.value = data.filter(
            (r: RedacaoCorrigida) => r.status == "S"
          ).sort((a: any, b: any) => a.aluno.nome.localeCompare(b.aluno.nome));

          redacoesCorrigidas.value = data.filter(
            (r: RedacaoCorrigida) => r.status == "C"
          ).sort((a: any, b: any) => a.aluno.nome.localeCompare(b.aluno.nome));

          redacao.value = data[0].redacao;
          modal.value = false;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar redações",
          });
        });
    };

    const formatData = (data: string): string => {
      return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getRedacoes();
    });

    return {
      redacoesSubmetidas,
      redacoesCorrigidas,
      redacaoSelecionada,
      formatData,
      redacao,
      filter1,
      filter2,
      columns,
      modal,
      openModal,
      urlImg,
      form,
      submit,
      refazer,
    };
  },
});
